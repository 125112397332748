<template>
  <b-card>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card-title v-if="d">{{ d.blockName }}</b-card-title>
    <div v-if="d">
      <b-carousel
        v-if="d.data && d.data.length"
        id="carousel-fade"
        fade
        indicators
        :interval="5000"
        class="course-carousel"
      >
        <b-carousel-slide
          v-for="(course, key) in d.data"
          :key="key"
        >
          <template #img>
            <div>
              <b-link
                :to="{ name: 'courses-preview', params: { id: course.id } }"
                class="courses-title-truncate text-body-heading relative text-center"
              >
                <div
                  v-if="course.c_imgPreview && course.c_imgPreview.length"
                  id="carousel-fade"
                  style="text-shadow: 0px 0px 2px #000;"
                  fade
                  :interval="3000"
                >
                  <b-img
                    :src="server + course.c_imgPreview[0]"
                    style="height: 232px"
                    fluid
                  />
                </div>
                <div
                  class="right-bottom"
                >
                  <small>{{ course.sCountLess }}</small>
                </div>
              </b-link>
              <div>
                <h5 class="mt-2">
                  <b-link
                    :to="{ name: 'courses-preview', params: { id: course.id } }"
                    class="courses-title-truncate text-body-heading"
                  >
                    {{ course.c_name }}
                  </b-link>
                </h5>
              </div>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <template v-if="d" #footer>
      <b-link
        :to="{ name: 'courses' }"
      >{{ d.linkName }}</b-link>
    </template>
  </b-card>
</template>
<script>
/* eslint-disable */
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BLink,
  BCarousel,
  BCarouselSlide,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BLink,
    BCarousel,
    BCarouselSlide,
    BImg,
    BOverlay,
  },
  props: {
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { root }) {
    const server = process.env.VUE_APP_SERVER
    const d = ref(null)
    const loading = ref(false)

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    return {
      server,
      d,
      loading,
    }
  },
}
</script>
<style scoped>
  .relative {
    position: relative;
  }

  .right-bottom {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: #fff;
    background: #0000007a;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 3px;
    z-index: 1;
  }

  .course-carousel >>> .carousel-indicators li {
    background-color: #000;
  }

  .course-carousel >>> .carousel-indicators {
    bottom: initial;
    top: 232px;
  }
</style>
