<template>
  <b-card>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card-title class="mb-0">
      Новости и Акции
    </b-card-title>
    <b-list-group
      class="news-list"
    >
      <b-list-group-item
        v-for="(item, key) in d"
        :key="key"
        class="d-flex pointer"
        :to="{ name: 'news-detail', params: { id: item.id } }"
      >
        <div
          class="news-img"
          :style="`background-color: #51C9AC;background-image: url('${server + JSON.parse(item.n_imgPreview)[0]}')`"
        />
        <div class="news-content col">
          <div class="d-flex justify-content-between">
            <div class="news-date">
              <small class="text-muted">{{ item.n_datePost }}</small>
            </div>
            <div class="news-tag">
              <span v-html="item.stype" />
            </div>
          </div>
          <div class="news-title">
            <h6>{{ item.n_header }}</h6>
          </div>
          <div class="news-desc">
            <div v-html="item.n_teaser" />
          </div>
          <div
            v-if="item.n_link"
            class="right-bottom"
          >
            <b-link
              target="blank"
              :href="item.n_link"
            >
              <feather-icon icon="LinkIcon" />
            </b-link>
          </div>
        </div>
      </b-list-group-item>
      <!-- <b-list-group-item
        class="d-flex"
      >
        <div class="news-content col p-0">
          <div class="d-flex justify-content-between">
            <div class="news-date">
              <small class="text-muted">17.05.2021</small>
            </div>
            <div class="news-tag">
              <b-badge
                pill
                variant="success"
              >
                Акция
              </b-badge>
            </div>
          </div>
          <div class="news-title">
            <h6>Внимание! Планируется вебинар с участием доктора Елизаветы Мельниковой</h6>
          </div>
          <div class="news-desc">
            Уважаемые партнеры!<br>
            Принимаем вопросы к доктору Елизавете Мелиниковой.
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex"
      >
        <div class="news-content col p-0">
          <div class="d-flex justify-content-between">
            <div class="news-date">
              <small class="text-muted" />
            </div>
            <div class="news-tag">
              <b-badge
                pill
                variant="danger"
              >
                Мероприятия
              </b-badge>
            </div>
          </div>
          <div
            class="news-img"
            style="background-color: #7367F0;width: 100%;margin-top: 8px;background-image: url('/images/dashboard/image8.png')"
          />
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex"
      >
        <div
          class="news-img"
          style="background-color: #E3669D;background-image: url('/images/dashboard/image9.png')"
        />
        <div class="news-content col">
          <div class="d-flex justify-content-between">
            <div class="news-date">
              <small class="text-muted">13.05.2021</small>
            </div>
            <div class="news-tag">
              <b-badge
                pill
                variant="primary"
              >
                Новости
              </b-badge>
            </div>
          </div>
          <div class="news-title">
            <h6>Сегодня в 18:00 мск презентация новинок компании “BIONATURICA”</h6>
          </div>
          <div class="news-desc">
            https://pruffme.com/landing/u815285/BIONATURICA - 13 мая в 18:00 мск презентация “BIONATURICA”
          </div>
        </div>
      </b-list-group-item> -->
    </b-list-group>
    <div class="news-buttons mt-2 d-flex justify-content-center">
      <b-button
        variant="outline-primary"
        :to="{ name: 'news' }"
      >
        Все новости
      </b-button>
    </div>
  </b-card>
</template>
<script>
/* eslint-disable */
import {
  BCard,
  BCardTitle,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    BLink,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const d = ref(JSON.parse(JSON.stringify(props.data)))
    const loading = ref(false)
    const server = process.env.VUE_APP_SERVER

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    return {
      server,
      d,
      loading,
    }
  },
}
</script>
<style scoped> /** news-list styles */
  .news-list >>> .list-group-item {
    border-left: none;
    border-right: none;
    border-radius: initial;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .news-list >>> .list-group-item:hover {
    background-color: transparent;
  }
  .news-list >>> .list-group-item:first-child {
    border-top: none;
  }
  .news-list .news-content {
    padding-right: 0;
  }
  .news-list .news-img {
    height: 160px;
    width: 236px;
    border-radius: 6px;
    background-size: cover;
  }
  .news-list .news-title {
    margin-bottom: 8px;
  }
  .news-list .news-title h6 {
    line-height: 21px;
  }
  .news-list .news-date {
    margin-bottom: 9px;
  }
  .news-list .news-desc {
    line-height: 21px;
    color: #6E6B7B;
    font-size: 14px;
  }

  .right-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
