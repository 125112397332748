<template>
  <div>
    <b-card title="Сообщения">
      <b-overlay
        :show="loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <app-timeline>
        <app-timeline-item
          v-for="(message, key) in messages"
          :key="key"
          :icon="message.icon"
          :variant="message.color"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ message.header }}</h6>
            <small class="text-muted">{{ message.sdate }}</small>
          </div>
          <p v-html="message.text" />
        </app-timeline-item>
      </app-timeline>
    </b-card>
    <b-card
      title="Узнавайте о новостях первыми, подпишитесь на наши соцсети"
      class="mt-2"
    >
      <div class="d-flex">
        <div
          class="share-network-telegram mr-50"
        >
          <a
            href="https://t.me/ProHealthFactor"
            target="blank"
            class="btn btn-telegram btn-sm"
          >
            <i class="fab fa-telegram" style="font-size: 24px;"></i>
          </a>
        </div>
        <div
          class="share-network-vk mr-50"
        >
          <a
            href="https://vk.com/prohealthfactor"
            target="blank"
            class="btn btn-vk btn-sm"
          >
            <i class="fab fa-vk" style="font-size: 24px;"></i>
          </a>
        </div>
        <div
          class="share-network-instagram mr-50"
        >
          <a
            href="https://instagram.com/prohealthfactor"
            target="blank"
            class="btn btn-instagram btn-sm"
          >
            <i class="fab fa-instagram" style="font-size: 24px;"></i>
          </a>
        </div>
        <div
          class="share-network-youtube"
        >
          <a
            href="https://www.youtube.com/@PROHEALTHFACTOR"
            target="blank"
            class="btn btn-youtube btn-sm"
          >
            <i class="fab fa-youtube" style="font-size: 24px;"></i>
          </a>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BOverlay,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import messagesStoreModule from './messagesStoreModule'
import store from '@/store'

export default {
  components: {
    BCard,
    BOverlay,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    api: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'html-content'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, messagesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { router } = useRouter()

    const apiId = router.currentRoute.params.id
    const params = router.currentRoute.query

    const messages = ref([])
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      store.dispatch('html-content/fetchData', {
        api: props.api,
        apiId,
        params,
      })
        .then(response => {
          const { data } = response.data
          messages.value = data
          loading.value = false
        })
        .catch(() => {
          messages.value = []
          loading.value = false
        })
    }

    if (props.api) {
      fetchData()
    }

    return {
      loading,
      messages,
    }
  },
}
</script>

<style>
  .btn-youtube {
    background-color: #FF0000;
    color: #fff!important;
  }
</style>
